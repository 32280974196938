// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// Redirect logo to main site
$(".mainLogo").prop("href", "https://www.kcl.ac.uk/");

$('.donationGiftAid label.checkboxRadio').first().append('<br /><br />To qualify for Gift Aid you must be a UK tax payer and pay at least as much Income and/or Capital Gains Tax as the amount to be reclaimed on all your charitable donations in the appropriate tax year and it is your responsibility to pay any difference. Please inform us if you no longer pay sufficient UK tax, change your name or home address, or wish to cancel at any time after the date of this declaration. <br /><br />Gift Aid is gratefully received and is used to support the College where it is needed the most.');
  
$('.formSecondStep.giftAidStatement').first().html('Yes, I would like King’s College London to treat all donations that I have made in the past 4 years and all future donations from the date of this declaration as Gift Aid donations');
